
import { computed, defineComponent, onActivated, ref, Ref, watch, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import Container from '@/components/Container.vue'
import { getTxsByAccount, getEverpayBalances, getBundleTxsByAccount, getAnsResolver } from '@/libs/api'
import TableCard from '@/components/TableCard.vue'
import Pagination from '@/components/common/Pagination.vue'
import { fromDecimalToUnit, getAccountChainType } from '@/libs/everpay-js/utils/util'
import { useStore } from '@/store'
import Loading from '@/components/common/Loading.vue'
import TokenLogo from '@/components/TokenLogo.vue'
import { useI18n } from 'vue-i18n'
import ClipboardJS from 'clipboard'
import { ElMessage } from 'element-plus'
import { formatMoney, checkParentsHas, handleErrorMsg, isSmartAccount, genEverId, formatChainTypeDisplay } from '@/libs/utils'
import Tab from '@/components/Tab.vue'
import { AllBundleTxs, AllTxs } from '@/pages/interface'
import NotFound from '@/components/common/NotFound.vue'
export default defineComponent({
  components: {
    TableCard,
    Container,
    Pagination,
    Loading,
    TokenLogo,
    Tab,
    NotFound
  },
  setup () {
    const route = useRoute()
    const store = useStore()
    const account = ref('')
    const accountId = computed(() => {
      return route.params.accountId as string
    })
    const isMailAccount = computed(() => {
      return isSmartAccount(accountId.value)
    })
    const isEid = computed(() => {
      if (accountId.value) {
        return accountId.value.search(/^eid[0-9a-fA-F]{68}$/) !== -1
      }
      return false
    })
    const isNotFoundEverId = computed(() => {
      let isNormalAccount = true
      try {
        getAccountChainType(accountId.value)
      } catch {
        isNormalAccount = false
      }
      return isMailAccount.value || !(isEid.value || isNormalAccount)
    })
    const formatAccount = computed(() => {
      if (accountId.value) {
        return (account.value).slice(0, 6) + '...' + (account.value).slice(-6)
      }
      return ''
    })
    const tokens = computed(() => store.state.tokenList)
    const currencyPrices = computed(() => store.state.currencyPrices)
    const displayAmountSelect = checkParentsHas('totalPriceOptions')
    const loading = ref(false)
    const hoverIndex = ref(-1)
    const currentPage = ref(1)
    const pageCacheIndex = ref(0)
    const pageCachelist = ref<number[]>([])
    const isNextPage = ref(false)
    const pageId = ref(0)
    const isShow = ref(false)
    const accountTxsList = ref({} as AllTxs | AllBundleTxs)
    const totalPriceList:Ref<any[]> = ref([])
    const ansAddress = ref('')
    const { t } = useI18n()
    const currencyPricesStack: Ref<any> = computed(() => {
      const result = {}
      store.state.currencyPrices.forEach(currencyPriceItem => {
        (result as any)[currencyPriceItem.symbol.toUpperCase()] = currencyPriceItem.price
      })
      return result
    })
    const genEverAccount = async (accountId:string) => {
      account.value = isMailAccount.value ? await genEverId(accountId) : accountId
      return account.value
    }
    const getAccountAnsAddress = async () => {
      try {
        const result = await getAnsResolver(accountId.value)
        ansAddress.value = result?.domain ? result.domain : ''
      } catch {
        ansAddress.value = ''
      }
    }
    const totalPrice = computed(() => {
      if (totalPriceList.value.length) {
        return totalPriceList.value.reduce((accumulator: number, currentValue: any) => {
          return accumulator + (+currentValue.totalAmount * +currentValue.unitPrice)
        }, 0)
      }
      return 0
    })
    const getTokenArea = async () => {
      totalPriceList.value = []
      if (accountId.value) {
        const acc = await genEverAccount(accountId.value)
        try {
          const accountEverBalances = await getEverpayBalances({ account: acc })
          const totalPrice = accountEverBalances.balances.map((item) => {
            const token = tokens.value.find((content) => {
              return content.tag === item.tag
            })
            const unitPrice = token ? currencyPricesStack.value[token?.symbol.toUpperCase()] : '0'
            return {
              tag: item.tag,
              chainType: token?.chainType.startsWith('arweave') ? 'arweave' : token?.chainType,
              unitPrice,
              decimals: item.decimals,
              symbol: token?.symbol,
              totalAmount: fromDecimalToUnit(item.amount, item.decimals)
            }
          })
          totalPriceList.value = totalPrice.filter((item) => {
            return +item.totalAmount !== 0
          }).sort((a: any, b: any) => {
            if ((+a.totalAmount * +a.unitPrice) || (+b.totalAmount * +b.unitPrice)) {
              return (+b.totalAmount * +b.unitPrice) - (+a.totalAmount * +a.unitPrice)
            } else {
              return +b.totalAmount - +a.totalAmount
            }
          })
        } catch (e:any) {
          handleErrorMsg(e, t)
        }
      }
    }
    const displayTotalAmount = ref(true)
    const tokenSymbolInfo = ref({} as any)
    const currentTokenTag = ref('')
    const switchTokenTagTx = (symbolInfo: any) => {
      tokenSymbolInfo.value = symbolInfo
      currentTokenTag.value = symbolInfo.tag
      displayTotalAmount.value = false
    }
    watch(() => currentTokenTag.value, () => {
      initConst()
      getAccountTxsList()
    })
    const currentTxOptions = ref('Transaction')
    const switchTxOptions = (option: string) => {
      currentTxOptions.value = option
      initConst()
      getAccountTxsList()
    }
    // 初始化变量
    const initConst = () => {
      currentPage.value = 1
      pageCachelist.value = []
      isNextPage.value = false
      pageId.value = 0
      pageCacheIndex.value = 0
    }
    const setAccountTxsList = async () => {
      const acc = await genEverAccount(accountId.value)
      if (pageCachelist.value[pageCacheIndex.value] !== undefined) {
        pageId.value = pageCachelist.value[pageCacheIndex.value]
      }
      // transaction
      if (currentTxOptions.value === 'Transaction') {
        accountTxsList.value = await getTxsByAccount(acc, pageId.value, currentTokenTag.value)
        isNextPage.value = accountTxsList.value.hasNextPage
        if (pageCachelist.value[pageCacheIndex.value] === undefined) {
          pageCachelist.value.push(pageId.value)
        }
        if (accountTxsList.value.hasNextPage) {
          pageId.value = accountTxsList.value.txs[accountTxsList.value.txs.length - 1].rawId
        }
      } else {
        // bundle
        accountTxsList.value = await getBundleTxsByAccount(acc, pageId.value, currentTokenTag.value)
        isNextPage.value = accountTxsList.value.hasNextPage
        if (pageCachelist.value[pageCacheIndex.value] === undefined) {
          pageCachelist.value.push(pageId.value)
        }
        if (accountTxsList.value.hasNextPage) {
          pageId.value = accountTxsList.value.txs[accountTxsList.value.txs.length - 1].id
        }
      }
    }
    const getAccountTxsList = async () => {
      if (accountId.value) {
        loading.value = true
        try {
          await setAccountTxsList()
          loading.value = false
        } catch (e:any) {
          loading.value = false
          handleErrorMsg(e, t)
          await setAccountTxsList()
        }
      }
    }
    const displayBalances = () => {
      hoverIndex.value = -1
      isShow.value = !isShow.value
    }
    const handlePageChange = (page: number) => {
      currentPage.value = page
      pageCacheIndex.value = currentPage.value - 1
      getAccountTxsList()
    }
    const init = async () => {
      try {
        if (!tokens.value.length) {
          await store.dispatch('updateTokenListAsync')
        }
        if (!currencyPrices.value || !currencyPrices.value.length) {
          await store.dispatch('updateCurrencyPricesAsnyc')
        }
      } catch (e:any) {
        handleErrorMsg(e, t)
      }
      getTokenArea()
    }
    onActivated(async () => {
      initConst()
      init()
      getAccountTxsList()
      getAccountAnsAddress()
      genEverAccount(accountId.value)
    })
    watch(() => accountId.value, async () => {
      displayTotalAmount.value = true
      currentTokenTag.value = ''
      isShow.value = false
      genEverAccount(accountId.value)
      getTokenArea()
      getAccountAnsAddress()
      getAccountTxsList()
      isShow.value = false
    })
    watch(() => tokens.value, () => {
      store.dispatch('updateCurrencyPricesAsnyc')
    })
    let clipboard: any
    let clipboardAnsAddress:any
    onMounted(async () => {
      window.addEventListener('resize', () => {
        isShow.value = false
      })
      document.addEventListener('click', (e) => {
        if (!displayAmountSelect(e.target as any)) {
          isShow.value = false
        }
      })
      clipboardAnsAddress = new ClipboardJS('.clipboard-modal-ansAddress')
      clipboardAnsAddress.on('success', function (e:any) {
        e.clearSelection()
        ElMessage({
          showClose: true,
          message: t('copy_success'),
          type: 'success',
          duration: 2000
        })
      })
      clipboard = new ClipboardJS('.clipboard-modal-accountId')
      clipboard.on('success', function (e: any) {
        e.clearSelection()
        ElMessage({
          showClose: true,
          message: t('copy_success'),
          type: 'success',
          duration: 2000
        })
      })
    })
    onUnmounted(() => {
      clipboard && clipboard.destroy()
    })
    return {
      t,
      accountId,
      hoverIndex,
      accountTxsList,
      totalPriceList,
      displayBalances,
      handlePageChange,
      isShow,
      loading,
      formatMoney,
      switchTxOptions,
      currentTxOptions,
      currentPage,
      switchTokenTagTx,
      tokenSymbolInfo,
      displayTotalAmount,
      currentTokenTag,
      formatAccount,
      ansAddress,
      account,
      isMailAccount,
      isNotFoundEverId,
      totalPrice,
      isNextPage,
      formatChainTypeDisplay
    }
  }
})
